import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ManageNavigation } from "./components/ManageNavigation";
import { PostsReport } from "./components/PostsReport";
import { MembersReport } from "./components/MembersReport";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { NoAccess } from "../../components/ui/NoAccess";
import { creatorIsAdmin, isAdminOfClient } from "../../utils/adminManager";
import { apiGetCommunity } from "../../services/apiGetCommunity";
import { Loader } from "../../components/ui/Loader";

export const ManageCommunity = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [community, setCommunity] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchCommunity = async () => {
            try {
                setIsLoading(true);
                const data = await apiGetCommunity(id);
                setCommunity(data);
            } catch (error) {
                console.error("Error fetching community:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCommunity();
    }, [id]);

    if (isLoading) return <Loader />;

    if (!creatorIsAdmin() || !isAdminOfClient(community?.cliente)) {
        return <NoAccess />;
    }

    return (
        <Box sx={{ px: 2, mt: 3 }}>
            <Typography
                variant="h4"
                sx={{
                    my: 2,
                    fontWeight: "bold",
                    color: "var(--grey-800-color)",
                    letterSpacing: 0.5,
                }}
            >
                Gerenciar Comunidade
            </Typography>

            <ManageNavigation />

            <Routes>
                <Route path="/" element={<Navigate to="posts" replace />} />
                <Route path="/posts" element={<PostsReport title="Relatório de Posts" defaultStatus={[]} />} />
                <Route path="/membros" element={<MembersReport />} />
                <Route
                    path="/moderacao"
                    element={<PostsReport title="Moderação de Conteúdos" defaultStatus={["Pendente"]} />}
                />
            </Routes>
        </Box>
    );
};
