import React from "react";
import { Box } from "@mui/material";

export const Gamification = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "var(--grey-100-color)",
                overflow: "hidden",
            }}
        >
            <iframe
                src={`/gamification.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}
                width="100%"
                height="100%"
                style={{
                    border: "none",
                    backgroundColor: "var(--grey-100-color)",
                }}
                title="Gamification PDF"
                allowFullScreen
                frameBorder="0"
            ></iframe>
        </Box>
    );
};
