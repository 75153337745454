import React, { useState } from "react";
import { Box, Modal, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { ButtonFL } from "../ButtonFL";

export const ConfirmPostModal = ({ open, onConfirm }) => {
    const [confirmed, setConfirmed] = useState(false);

    const handleConfirm = () => {
        if (confirmed) {
            onConfirm();
        }
    };

    return (
        <Modal open={open} aria-labelledby="confirm-modal-title" aria-describedby="confirm-modal-description">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                        xs: "85%",
                        sm: "80%",
                        md: "60%",
                    },
                    maxWidth: 600,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                    },
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Typography
                    id="confirm-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                        mb: 2,
                        fontSize: {
                            xs: "1.1rem",
                            sm: "1.2rem",
                            md: "1.3rem",
                        },
                    }}
                >
                    Confirmação de Autorização
                </Typography>

                <Typography variant="body1" sx={{ mb: 3 }}>
                    Declaro que li e aceito os Termos e Condições de Uso da Plataforma Wizard, compreendendo que a
                    plataforma disponibiliza conteúdos educacionais, como videoaulas, testes e simulados, para
                    professores e instituições de ensino. Estou ciente das definições e regras estabelecidas e continuo
                    de acordo com seu uso.
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={confirmed}
                            onChange={(e) => setConfirmed(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Confirmo que autorizo a publicação deste conteúdo"
                    sx={{ mb: 2 }}
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <ButtonFL onClick={handleConfirm} disabled={!confirmed} variant="contained">
                        Confirmar
                    </ButtonFL>
                </Box>
            </Box>
        </Modal>
    );
};
