import { useCallback } from "react";
import DOMPurify from "dompurify";

export const useFormattedText = () => {
    const convertUrlsToLinks = useCallback((text) => {
        const urlRegex = /(https?:\/\/[^\s<]+[^<.,:;"'\]\s])/g;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    }, []);

    const sanitizeContent = useCallback(
        (htmlContent) => {
            if (!htmlContent) return "";

            const contentWithLinks = convertUrlsToLinks(htmlContent);

            return DOMPurify.sanitize(contentWithLinks, {
                ALLOWED_TAGS: ["p", "strong", "em", "u", "h1", "h2", "h3", "br", "div", "span", "a"],
                ALLOWED_ATTR: ["style", "class", "href", "target", "rel"],
            });
        },
        [convertUrlsToLinks],
    );

    const getTruncatedContent = useCallback((content) => {
        if (!content) return "";

        const firstParagraphMatch = content.match(/<p[^>]*>.*?<\/p>/s);
        if (!firstParagraphMatch) return content;

        const indexAfterFirstP = content.indexOf(firstParagraphMatch[0]) + firstParagraphMatch[0].length;
        const truncatedContent = content.slice(0, indexAfterFirstP);

        return content.length > indexAfterFirstP ? truncatedContent : content;
    }, []);

    return {
        sanitizeContent,
        getTruncatedContent,
    };
};
