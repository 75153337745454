import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CampaignIcon from "@mui/icons-material/Campaign";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ReactComponent as IconPublicacao } from "../_assets/icons/wizard/iconPublicacao.svg";
import { ReactComponent as IconLearn } from "../_assets/icons/wizard/iconAprenda.svg";
import { returnCampainId, returnEnvironment } from "../utils";

const environment = returnEnvironment();
const campaignId = environment === "wizard" ? returnCampainId() : null;

const MENU_CONFIG = {
    icons: {
        tasks: {
            default: DvrOutlinedIcon,
            active: CampaignIcon,
        },
        communities: {
            default: ForumOutlinedIcon,
            active: ForumIcon,
        },
        community: {
            default: ForumOutlinedIcon,
            active: ForumIcon,
        },
        faq: {
            default: SchoolOutlinedIcon,
            active: SchoolIcon,
        },
        learn: {
            default: SchoolOutlinedIcon,
            active: SchoolIcon,
        },
        post: {
            default: IconPublicacao,
            active: IconPublicacao,
        },
        manageCommunity: {
            default: AdminPanelSettingsOutlinedIcon,
            active: AdminPanelSettingsIcon,
        },
        manageCommunities: {
            default: AdminPanelSettingsOutlinedIcon,
            active: AdminPanelSettingsIcon,
        },
        gamification: {
            default: EmojiEventsOutlinedIcon,
            active: EmojiEventsIcon,
        },
    },
    routes: {
        tasks: {
            default: "/campanhas",
            wizard: "/campanhas/:campaignId",
        },
        communities: {
            default: "/comunidades",
        },
        community: {
            wizard: "/comunidade/:campaignId",
        },
        faq: {
            default: "/aprenda",
        },
        learn: {
            default: "/aprenda",
        },
        post: {
            wizard: "/novoPost/:campaignId",
        },
        manageCommunity: {
            wizard: "/comunidade/:campaignId/gerenciar/",
        },
        manageCommunities: {
            default: "/comunidades/administradas",
        },
        gamification: {
            default: "/gamification",
            wizard: "/gamification",
        },
    },
};

const MENU_STRUCTURE = [
    {
        id: "tasks",
        title: "Tarefas",
        order: 1,
        environments: ["default"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "manageCommunities",
        title: "Administrar",
        order: 2,
        environments: ["default"],
        adminOnly: true,
        showMobile: true,
    },
    {
        id: "communities",
        title: "Comunidades",
        order: 0,
        environments: ["default"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "community",
        title: "Comunidade",
        order: 2,
        environments: ["wizard"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "faq",
        title: "FAQ",
        order: 3,
        environments: ["default"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "post",
        title: "Postar",
        order: 2,
        environments: ["wizard"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "learn",
        title: "Aprenda",
        order: 3,
        environments: ["wizard"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "gamification",
        title: "Gamificação",
        order: 4,
        environments: ["wizard"],
        adminOnly: false,
        showMobile: true,
    },
    {
        id: "manageCommunity",
        title: "Gerenciar Comunidade",
        order: 5,
        environments: ["wizard"],
        adminOnly: true,
        showMobile: false,
    },
];

const buildMenuItem = (item, { creatorIsAdmin, isMobile }) => {
    const icons = MENU_CONFIG.icons[item.id] || {};
    const routes = MENU_CONFIG.routes[item.id] || {};
    const currentEnv = returnEnvironment();

    const isAllowedClient = item.environments.includes(currentEnv);
    const matchesAdmin = item.adminOnly ? creatorIsAdmin : true;
    const matchesMobile = isMobile ? item.showMobile !== false : true;

    if (!isAllowedClient || !matchesAdmin || !matchesMobile) {
        return null;
    }

    return {
        title: item.title,
        icon: icons.default || icons.active,
        activeIcon: icons.active || icons.default,
        link: (routes[currentEnv] || routes.default || "").replace(":campaignId", campaignId),
        allowedClients: item.environments.map((env) => ({
            clientType: env,
            order: item.order,
        })),
        adminOnly: item.adminOnly,
        showMobile: item.showMobile,
        mobileOnly: item.mobileOnly,
    };
};

export const buildMenuItems = ({ creatorIsAdmin, isMobile }) => {
    const items = MENU_STRUCTURE.map((item) => {
        return buildMenuItem(item, { creatorIsAdmin, isMobile });
    })
        .filter(Boolean)
        .sort((a, b) => {
            if (a.adminOnly && !b.adminOnly) return 1;
            if (!a.adminOnly && b.adminOnly) return -1;
            return a.allowedClients[0].order - b.allowedClients[0].order;
        });

    return items;
};
