import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    Divider,
    Checkbox,
    FormControlLabel,
    Link,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { format } from "date-fns";
import { apiGetMinhasOportunidades } from "../../services/apiGetMinhasOportunidades";
import { useAuthStore } from "../../store/useAuthStore";
import { apiPostOportunidadeCreator } from "../../services/apiPostOportunidadeCreator";
import { Loader } from "../../components/ui/Loader";
import { useNavigate } from "react-router-dom";
import { TermsModal } from "../../components/TermsModal";

export const Opportunity = ({ opportunity, closeModal }) => {
    const [myOpportunities, setMyOpportunities] = useState([]);
    const [showMore, setShowMore] = useState({});
    const [opportunityStatus, setOpportunityStatus] = useState("loading");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const handleOpen = (event) => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);
    const handleSubscribe = async () => {
        try {
            setLoading(true);
            const data = { oportunidade: opportunity.id, creator: creator.id };
            await apiPostOportunidadeCreator(data);

            setMyOpportunities((prev) => [...prev, { oportunidade: opportunity.id, post: false }]);
        } catch (error) {
            console.error("Erro ao se inscrever na oportunidade:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePublish = () => {
        const opportunityData = myOpportunities.find((op) => op.oportunidade === opportunity.id);

        navigate(`/novoPost/${opportunity.comunidade}`, {
            state: { oportunidadecreator: opportunityData?.id },
        });
    };

    const formatField = (field, fallback) => {
        if (!field) return fallback;
        if (!isNaN(Date.parse(field))) return format(new Date(field), "dd/MM/yyyy");
        return field;
    };

    const handleChange = (event) => {
        const { checked } = event.target;
        setIsChecked(checked);
    };

    useEffect(() => {
        const fetchMyOpportunities = async () => {
            try {
                const data = await apiGetMinhasOportunidades(creator?.id);
                setMyOpportunities(data);
            } catch (error) {
                console.error("Erro ao buscar oportunidades:", error);
            }
        };

        fetchMyOpportunities();
    }, [creator?.id, opportunity]);

    useEffect(() => {
        const updateOpportunityStatus = () => {
            if (!myOpportunities || !opportunity) {
                setOpportunityStatus("loading");
                return;
            }

            const existingOpportunity = myOpportunities.find((op) => op.oportunidade === opportunity.id);

            if (existingOpportunity) {
                setOpportunityStatus(existingOpportunity.post ? "posted" : "publishable");
            } else {
                setOpportunityStatus("notExists");
            }
        };

        updateOpportunityStatus();
    }, [myOpportunities]);

    if (!opportunity || opportunityStatus === "loading") {
        return <Loader />;
    }

    opportunity.termos_condicoes = opportunity.termos_condicoes.replace(/<p><br><\/p>/g, "");

    return (
        <>
            <Card
                sx={{
                    width: "90%",
                    maxWidth: "100%",
                    px: 5,
                    py: 0,
                    borderRadius: "8px",
                    backgroundColor: "var(--white-color)",
                    boxShadow: "none",
                }}
            >
                {opportunity.titulo && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                        <Typography variant="h5" fontWeight="bold" sx={{ color: "var(--grey-800-color)" }}>
                            {opportunity.titulo}
                        </Typography>
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
                <Divider sx={{ marginBottom: 2 }} />

                <CardContent
                    sx={{
                        padding: 0,
                        maxHeight: "calc(90vh - 100px)",
                        overflowY: "auto",
                        marginBottom: "60px",
                    }}
                >
                    {opportunity.recompensa_valor && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Recompensa
                            </Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                                <WorkspacePremiumIcon fontSize="small" />
                                <Typography variant="body1">{opportunity.recompensa_valor} pontos</Typography>
                            </Box>
                        </Box>
                    )}

                    {opportunity.entregas && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Entregas
                            </Typography>
                            <Typography variant="body1">{opportunity.entregas}</Typography>
                        </Box>
                    )}
                    <Box marginBottom={2}>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Prazo para entrega
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <CalendarTodayIcon fontSize="small" />
                            <Typography variant="body1">
                                {formatField(opportunity.prazo_entrega, "Sem prazo definido")}
                            </Typography>
                        </Box>
                    </Box>

                    {opportunity.vagas > 0 && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Número de vagas
                            </Typography>
                            <Typography variant="body1">{opportunity.vagas}</Typography>
                        </Box>
                    )}
                    {opportunity.requisitos && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Requisitos
                            </Typography>
                            <Typography variant="body1">
                                {opportunity.requisitos || "Sem requisitos definidos"}
                            </Typography>
                        </Box>
                    )}

                    {opportunity.detalhes && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Detalhes da oportunidade
                            </Typography>
                            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: opportunity.detalhes }} />
                        </Box>
                    )}

                    {opportunityStatus !== "publishable" &&
                        opportunityStatus !== "posted" &&
                        opportunity.termos_condicoes && (
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <FormControlLabel
                                        label="Eu aceito os"
                                        control={
                                            <Checkbox
                                                name="termsAccepted"
                                                checked={isChecked}
                                                onChange={handleChange}
                                                sx={{ color: "var(--primary-color)" }}
                                            />
                                        }
                                        style={{ marginRight: "5px" }}
                                    />
                                    <span
                                        style={{
                                            color: "var(--primary-color)",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            textAlign: "left",
                                            marginLeft: 0,
                                        }}
                                        onClick={(e) => {
                                            handleOpen();
                                        }}
                                    >
                                        Termos e Condições
                                    </span>
                                </Box>
                            </Box>
                        )}
                </CardContent>

                <Box
                    sx={{
                        position: "absolute",
                        bottom: 40,
                        left: 40,
                        right: 40,
                        backgroundColor: "white",
                        zIndex: 1,
                    }}
                >
                    {opportunityStatus === "publishable" ? (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handlePublish}
                            sx={{
                                backgroundColor: "var(--warning-color)",
                                color: "var(--white-color)",
                            }}
                        >
                            Publicar Desafio
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubscribe}
                            disabled={loading || (opportunity.termos_condicoes && !isChecked)}
                            sx={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white-color)",
                                "&:hover": {
                                    backgroundColor: "var(--primary-light-color)",
                                },
                            }}
                        >
                            {loading ? "Carregando..." : "Quero participar!"}
                        </Button>
                    )}
                </Box>
            </Card>
            <TermsModal
                open={open}
                onClose={handleClose}
                title="Termos e Condições"
                description={opportunity.termos_condicoes}
            />
        </>
    );
};
