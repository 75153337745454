import { styled } from '@mui/system';
import { AppBar, Box } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: 'var(--primary-color)',
    height: '90px',
    padding: theme.spacing(1),
    boxShadow: 'none',
    width: '100%',
    position: 'relative',
    zIndex: 1000,
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 600px)': {
        flex: '0 0 auto',
        maxWidth: '300px',
    },
}));

export const Logo = styled('img')({
    height: '65px',
    '@media (max-width: 280px)': {
        height: '55px',
    },
    '@media (min-width: 600px)': {
        height: '70px',
    },
});

export const MenuContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingRight: '2%',
}));
