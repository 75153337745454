import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Avatar,
    Box,
    Typography,
    Paper,
    IconButton,
    Button,
    Stack,
    Chip,
    FormControl,
    styled,
    AppBar,
    Toolbar,
    Collapse,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TagIcon from "@mui/icons-material/Tag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuthStore } from "../../store/useAuthStore";
import { Post } from "../../http/httpVerbs";
import { Loader } from "../../components/ui/Loader";
import { useErrorStore } from "../../store/useErrorStore";
import { ButtonFL } from "../../components/ui/ButtonFL";
import { apiGetCommunity } from "../../services/apiGetCommunity";
import { CATEGORY_DESAFIO } from "../../utils/constants";
import { AuthorizationModal } from "../../components/AuthorizationModal";
import { clickSignTypes } from "../../constants/clicksignTypes";
import { getImageAuthorization } from "../../services/apiGetClicksign";
import { TextEditor } from "../../components/ui/TextEditor";
import { useFormattedText } from "../../hooks/useFormattedText";
import { ConfirmPostModal } from "../../components/ui/ConfirmPostModal";

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const NewPost = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const { state } = location;

    const showError = useErrorStore((state) => state.showError);
    const creator = useAuthStore((state) => state.creator);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [attachedFile, setAttachedFile] = useState(null);
    const [attachedLink, setAttachedLink] = useState("");
    const [linkError, setLinkError] = useState("");
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [showLinkSection, setShowLinkSection] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoryError, setCategoryError] = useState([]);
    const [community, setCommunity] = useState([]);
    const [showCategoryExpanded, setShowCategoryExpanded] = useState(false);

    const [showVideoInput, setShowVideoInput] = useState(false);
    const [showPhotoInput, setShowPhotoInput] = useState(false);
    const [showLinkAnchor, setShowLinkAnchor] = useState(true);

    const [filePreviewUrl, setFilePreviewUrl] = useState(null);
    const [modalOpen, setModalOpen] = useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [statusAuthorization, setStatusAuthorization] = useState("");

    const fileInputRef = useRef(null);

    const { sanitizeContent } = useFormattedText();

    const handleInputChange = (value) => {
        setInputValue(value);
    };

    const handleShowLink = () => {
        setShowLinkSection(!showLinkSection);
        if (!showLinkInput) {
            setShowLinkInput(true);
        }
    };

    const handleShowCategories = () => {
        setShowCategoryExpanded(!showCategoryExpanded);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (showVideoInput && !file.type.startsWith("video/")) {
                alert("Por favor, selecione um arquivo de vídeo válido");
                return;
            }
            if (showPhotoInput && !file.type.startsWith("image/")) {
                alert("Por favor, selecione uma imagem válida");
                return;
            }
            if (file.size > 100 * 1024 * 1024) {
                alert("O arquivo é muito grande. O tamanho máximo é 100MB");
                return;
            }
            setAttachedFile(file);
            setFilePreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleRemoveFile = () => {
        if (filePreviewUrl) {
            URL.revokeObjectURL(filePreviewUrl);
        }
        setAttachedFile(null);
        setFilePreviewUrl(null);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategories((prevSelected) => {
            const newSelected = prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId];

            setCategoryError(newSelected.length === 0 ? "Selecione pelo menos uma editoria" : "");
            return newSelected;
        });
    };

    const handleLinkChange = (e) => {
        const value = e.target.value;
        setAttachedLink(value);

        if (value && !value.startsWith("http://") && !value.startsWith("https://")) {
            setLinkError("O link deve começar com http:// ou https://");
        } else {
            setLinkError("");
        }
    };

    const validateForm = () => {
        let isValid = true;

        if (selectedCategories.length === 0) {
            setCategoryError("Selecione pelo menos uma editoria");
            isValid = false;
        }

        if (showLinkInput && attachedLink) {
            if (!attachedLink.startsWith("http://") && !attachedLink.startsWith("https://")) {
                setLinkError("O link deve começar com http:// ou https://");
                isValid = false;
            }
        }

        return isValid;
    };

    const handleSubmit = async () => {
        if (statusAuthorization !== "close") {
            handleOpen();
            return;
        }

        if (!validateForm()) {
            return;
        }

        const formData = new FormData();

        formData.append("campanha", Number(id));
        formData.append("texto", sanitizeContent(inputValue));
        formData.append("creator", creator.id);
        if (state?.oportunidadecreator) {
            formData.append("oportunidadecreator", state.oportunidadecreator);
        }

        const categories = state?.oportunidadecreator ? [...selectedCategories, CATEGORY_DESAFIO] : selectedCategories;

        formData.append("categorias", JSON.stringify(categories));

        if (attachedFile) formData.append("file", attachedFile);
        if (attachedLink) formData.append("link", attachedLink);

        try {
            setIsLoading(true);

            await Post(`${process.env.REACT_APP_API_URL}/posts/`, formData);

            setInputValue("");
            setAttachedFile(null);
            setAttachedLink("");
            setSelectedCategories([]);

            navigate(`/comunidade/${id}`);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            if (id) {
                const dataCommunity = await apiGetCommunity(id);

                setCommunity(dataCommunity);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    const showHideModal = (status) => {
        setStatusAuthorization(status);

        // Fecha o modal apenas se tiver um registro (status === "close")
        if (status === "close") {
            setModalOpen(false);
            setConfirmModalOpen(true);
        }
    };

    const getAuthorizationStatus = async (notification) => {
        try {
            const response = await getImageAuthorization(clickSignTypes.IMAGE_AUTHORIZATION, creator.id, notification);
            showHideModal(response.status);
            return response;
        } catch (error) {
            console.log("AutorizationModal error", error);
            showError(error);
        }
    };

    const handleSignDocument = async () => {
        try {
            const response = await getAuthorizationStatus();
            if (response?.signUrl) {
                window.open(response.signUrl, "_blank");
            }
        } catch (error) {
            console.error("Erro ao abrir documento para assinatura:", error);
            showError("Erro ao abrir documento para assinatura. Tente novamente.");
        }
    };

    const updateComponentState = async () => {
        try {
            setIsLoading(true);

            const response = await getImageAuthorization(clickSignTypes.IMAGE_AUTHORIZATION, creator.id);
            showHideModal(response.status);
        } catch (error) {
            console.error("Erro ao atualizar o estado do componente:", error);
            showError("Erro ao atualizar. Tente novamente.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAuthorizationStatus();
    }, []);

    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        if (community?.tipos) {
            setShowVideoInput(community.tipos.includes("VIDEO"));
            setShowPhotoInput(community.tipos.includes("FOTO"));
        }
    }, [community?.tipos]);

    useEffect(() => {
        if (attachedFile && attachedFile.type.startsWith("video/")) {
            const url = URL.createObjectURL(attachedFile);
            setFilePreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [attachedFile]);

    if (isLoading) return <Loader />;

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "var(--grey-100-color)",
                    color: "var(--grey-800-color)",
                    height: "100vh",
                    width: "100vw",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 1200,
                }}
            >
                <AppBar position="sticky" sx={{ backgroundColor: "var(--primary-color)" }}>
                    <Toolbar sx={{ minHeight: { xs: "56px" } }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => navigate(`/comunidade/${id}`)}
                            sx={{ mr: 2 }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                            Nova postagem
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        p: 2,
                        pb: "120px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {(showVideoInput || showPhotoInput || showLinkAnchor) && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                                accept={showVideoInput ? "video/*" : "image/*"}
                            />
                            {filePreviewUrl ? (
                                <Box
                                    sx={{
                                        width: { xs: "40%", sm: "25%" },
                                        aspectRatio: "9/16",
                                        position: "relative",
                                        borderRadius: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    {showVideoInput ? (
                                        <video
                                            src={filePreviewUrl}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            controls
                                        />
                                    ) : (
                                        <img
                                            src={filePreviewUrl}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            alt="Preview"
                                        />
                                    )}
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveFile();
                                        }}
                                        sx={{
                                            position: "absolute",
                                            top: 8,
                                            right: 8,
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                            "&:hover": {
                                                backgroundColor: "rgba(0,0,0,0.7)",
                                            },
                                        }}
                                    >
                                        <CloseIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => fileInputRef.current.click()}
                                    sx={{
                                        width: { xs: "40%", sm: "25%" },
                                        aspectRatio: "9/16",
                                        backgroundColor: "var(--white-color)",
                                        border: "1px solid var(--grey-200-color)",
                                        borderRadius: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        transition: "all 0.2s",
                                        "&:hover": {
                                            backgroundColor: "var(--grey-50-color)",
                                            transform: "translateY(-1px)",
                                            boxShadow: "0 2px 4px rgba(0,0,0,0.05), 0 2px 4px rgba(0,0,0,0.06)",
                                        },
                                    }}
                                >
                                    {showVideoInput ? (
                                        <VideoCallIcon sx={{ fontSize: 48, color: "var(--grey-800-color)", mb: 1 }} />
                                    ) : (
                                        <ImageIcon sx={{ fontSize: 40, color: "var(--grey-800-color)", mb: 1 }} />
                                    )}
                                    <Typography
                                        variant="body2"
                                        color="var(--grey-800-color)"
                                        align="center"
                                        sx={{ px: 2 }}
                                    >
                                        {showVideoInput ? "Clique aqui para escolher o vídeo" : "Enviar foto"}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    <Box sx={{ p: 2, bgcolor: "background.paper" }}>
                        <TextEditor editorContent={inputValue} setEditorContent={handleInputChange} height="150px" />
                    </Box>

                    <Box>
                        <Box
                            onClick={handleShowCategories}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "12px 0",
                                cursor: "pointer",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="subtitle2" color="var(--grey-800-color)" sx={{ fontWeight: 600 }}>
                                    Categoria
                                </Typography>
                            </Box>
                            {showCategoryExpanded ? (
                                <ExpandLessIcon sx={{ color: "var(--grey-800-color)" }} />
                            ) : (
                                <ExpandMoreIcon sx={{ color: "var(--grey-800-color)" }} />
                            )}
                        </Box>

                        <Box
                            sx={{
                                overflowX: showCategoryExpanded ? "visible" : "auto",
                                whiteSpace: showCategoryExpanded ? "normal" : "nowrap",
                                pb: showCategoryExpanded ? 2 : 1,
                                backgroundColor: "transparent",
                                borderRadius: "0 0 8px 8px",
                            }}
                        >
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexWrap: showCategoryExpanded ? "wrap" : "nowrap",
                                    listStyle: "none",
                                    p: 0.5,
                                    m: 0,
                                    boxShadow: "none",
                                    backgroundColor: "transparent",
                                    overflowX: showCategoryExpanded ? "visible" : "auto",
                                    "&::-webkit-scrollbar": {
                                        height: "6px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                                        borderRadius: "3px",
                                    },
                                }}
                                component="ul"
                            >
                                {community?.categorias?.map((category) => (
                                    <ListItem
                                        key={category}
                                        sx={{
                                            width: "auto",
                                            padding: showCategoryExpanded ? "4px" : "4px 2px",
                                        }}
                                    >
                                        <Chip
                                            key={category}
                                            label={category.toUpperCase()}
                                            clickable
                                            sx={{
                                                bgcolor: selectedCategories.includes(category)
                                                    ? "var(--newpost-cattegorychip-color)"
                                                    : "color-mix(in srgb, var(--newpost-cattegorychip-color), white 60%)",
                                                color: selectedCategories.includes(category)
                                                    ? "var(--newpost-cattegorychiptext-color)"
                                                    : "var(--newpost-cattegorychiptextselected-color)",
                                                height: "24px",
                                                fontSize: "14px",
                                                "& .MuiChip-label": {
                                                    padding: "0 8px",
                                                },
                                                "& .MuiChip-deleteIcon": {
                                                    fontSize: "18px",
                                                    margin: "0 5px 0 -6px",
                                                    color: selectedCategories.includes(category)
                                                        ? "var(--white-color)"
                                                        : "inherit",
                                                },
                                                "&.MuiChip-clickable:hover": {
                                                    bgcolor: selectedCategories.includes(category)
                                                        ? "var(--newpost-cattegorychip-color)"
                                                        : "color-mix(in srgb, var(--newpost-cattegorychip-color), white 60%)",
                                                },
                                                "&.MuiChip-clickable:active": {
                                                    boxShadow: "none",
                                                },
                                            }}
                                            onClick={() => handleCategoryClick(category)}
                                            onDelete={
                                                selectedCategories.includes(category)
                                                    ? () => handleCategoryClick(category)
                                                    : null
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </Paper>
                            {categoryError && (
                                <Typography variant="caption" color="error" sx={{ pl: 1, pt: 1, display: 'block' }}>
                                    {categoryError}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {/* Seção de Link temporariamente removida */}
                    {/* <Box
                    onClick={handleShowLink}
                    sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px",
                        backgroundColor: "var(--grey-100-color)",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <LinkIcon sx={{ color: "var(--grey-800-color)" }} />
                        <Typography variant="subtitle2" color="var(--grey-800-color)" sx={{ fontWeight: 600 }}>
                            Quero acumular mais pontos
                        </Typography>
                    </Box>
                    {showLinkSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>

                <Collapse in={showLinkSection}>
                    <Box
                        sx={{
                            backgroundColor: "var(--grey-50-color)",
                            border: "1px solid var(--grey-200-color)",
                            borderTop: "none",
                            padding: "16px",
                        }}
                    >
                        <TextField
                            fullWidth
                            placeholder="Cole aqui o link do seu conteúdo"
                            value={attachedLink}
                            onChange={handleLinkChange}
                            error={!!linkError}
                            helperText={linkError}
                            sx={{
                                backgroundColor: "var(--white-color)",
                            }}
                        />
                    </Box>
                </Collapse> */}

                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            p: 2,
                            backgroundColor: "var(--white-color)",
                            borderTop: "1px solid var(--grey-200-color)",
                            boxShadow: "0px -4px 6px -1px rgba(0, 0, 0, 0.1), 0px -2px 4px -1px rgba(0, 0, 0, 0.06)",
                            zIndex: 2,
                        }}
                    >
                        <ButtonFL textKey="Publicar" onClick={handleSubmit} bgcolor="var(--botao-acao)" fullWidth />
                    </Box>
                </Box>
            </Box>

            <AuthorizationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                getAuthorizationStatus={getAuthorizationStatus}
                updateComponentState={updateComponentState}
                onSignDocument={handleSignDocument}
            />
            <ConfirmPostModal
                open={!modalOpen && confirmModalOpen && statusAuthorization === "close"}
                onConfirm={() => setConfirmModalOpen(false)}
            />
        </>
    );
};
