import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, IconButton, Tooltip, Chip, Divider } from "@mui/material";
import { AdminPanelSettings, Sort, CalendarToday, Group, Category } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Loader } from "../../components/ui/Loader";
import { NoAccess } from "../../components/ui/NoAccess";
import { creatorIsAdmin, getAdminClientsList } from "../../utils/adminManager";
import { Get } from "../../http/httpVerbs";
import { useErrorStore } from "../../store/useErrorStore";

export const ManageCommunities = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [communities, setCommunities] = useState([]);
    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        const fetchCommunities = async () => {
            try {
                setIsLoading(true);
                const adminClients = getAdminClientsList();

                // Se não for admin ou não tiver clientes para administrar
                if (!creatorIsAdmin() || adminClients.length === 0) {
                    setCommunities([]);
                    return;
                }

                // Buscar todas as comunidades
                const response = await Get(`${process.env.REACT_APP_API_URL}/comunidades`);
                
                // Filtrar apenas as comunidades que o usuário é admin
                const filteredCommunities = response.filter(community => 
                    adminClients.includes(community.cliente?.toString())
                );
                
                setCommunities(filteredCommunities);
            } catch (error) {
                console.error("Error fetching communities:", error);
                showError(error.message);
                setCommunities([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCommunities();
    }, [showError]);

    if (isLoading) return <Loader />;

    if (!creatorIsAdmin() || communities.length === 0) {
        return <NoAccess />;
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4">
                    Comunidades que Administro
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Ordenar por Data">
                        <IconButton size="small">
                            <CalendarToday />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ordenar por Nome">
                        <IconButton size="small">
                            <Sort />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <Grid container spacing={2}>
                {communities.map((community) => (
                    <Grid item xs={12} key={community.id}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                                            <Typography variant="h6" component="div">
                                                {community.titulo}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Cliente: {community.clienteNome}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                                            {community.tipos && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Category fontSize="small" color="action" />
                                                    {community.tipos.map((tipo, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={tipo}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            <Divider orientation="vertical" flexItem />
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <CalendarToday fontSize="small" color="action" />
                                                <Typography variant="body2" color="text.secondary">
                                                    {new Date(community.created_at).toLocaleDateString()}
                                                </Typography>
                                            </Box>
                                            {community.creators && (
                                                <>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Group fontSize="small" color="action" />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {community.creators.length} creators
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <Tooltip title="Administrar Comunidade">
                                        <IconButton
                                            color="primary"
                                            component={Link}
                                            to={`/comunidade/${community.id}/gerenciar`}
                                        >
                                            <AdminPanelSettings />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
