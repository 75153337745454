import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography, Button, LinearProgress, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import * as S from "./styles";
import crownImage from "../../_assets/images/wizard/crown.png";
import { apiGetRanking } from "../../services/apiGetRanking";
import { apiGetRankingPeriodos } from "../../services/apiGetRankingPeriodos";
import { getCurrentPeriod } from "../../utils/getCurrentPeriod";
import { useMobileDetect } from "../../hooks";
import { BackButtonFL } from "../../components/ui/BackButtonFL";

export const Ranking = () => {
    const navigator = useNavigate();
    const { id } = useParams();
    const [rankingData, setRankingData] = useState([]);
    const [rankingPeriodos, setRankingPeriodos] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const isMobile = useMobileDetect();

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                setIsLoading(true);

                // Buscar períodos de pontuação
                const periodsResponse = await apiGetRankingPeriodos({ campanha: id });
                setRankingPeriodos(periodsResponse);

                // Encontrar o período atual
                const period = getCurrentPeriod(periodsResponse);
                setCurrentPeriod(period);

                // Buscar dados do ranking usando o período atual
                if (period) {
                    const rankingResponse = await apiGetRanking({ community: id, periodo: period.id });
                    setRankingData(rankingResponse);
                } else {
                    setRankingData([]);
                }
            } catch (err) {
                setError("Não foi possível carregar o ranking");
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchRanking();
        }
    }, [id]);

    if (!id) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">ID da comunidade não encontrado</Typography>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    // Calcular progresso do período atual
    const calculateProgress = (startDate, endDate) => {
        const now = new Date();
        const total = new Date(endDate) - new Date(startDate);
        const elapsed = now - new Date(startDate);
        return Math.min(100, Math.max(0, (elapsed / total) * 100));
    };

    const periodProgress = currentPeriod ? calculateProgress(currentPeriod.data_inicio, currentPeriod.data_fim) : 0;

    // Função para renderizar um usuário na lista
    const renderUserItem = (user) => (
        <Box
            component={user.current_user ? S.UserRankBox : S.RankingListItem}
            key={user.creator_id}
            sx={{
                width: "100%",
                margin: 0,
                boxSizing: "border-box",
            }}
        >
            <Typography
                sx={{
                    minWidth: "40px",
                    pl: 1,
                    mr: 2,
                    fontWeight: 700,
                }}
            >
                {user.position}º
            </Typography>
            <Avatar src={user.avatar_url} sx={{ width: 32, height: 32, mr: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
                <Typography>
                    {user.creator_name}
                    {user.current_user ? " (você)" : ""}
                </Typography>
            </Box>
            <Typography
                sx={{
                    color: !user.current_user && "#00A3FF",
                }}
            >
                {user.points}
            </Typography>
        </Box>
    );

    // Verifica se o usuário atual está no top 3
    const isCurrentUserInTop3 = rankingData.slice(0, 3).some((user) => user.current_user);

    // Verifica se o usuário atual está entre as posições 4-10
    const isCurrentUserInTop10 = rankingData.slice(3, 10).some((user) => user.current_user);

    // Encontra o usuário atual
    const currentUser = rankingData.find((user) => user.current_user);

    const handleGoBack = () => navigator(-1);

    return (
        <S.Container>
            {!isMobile && (
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                    sx={{ px: 2, pt: 3 }}
                ></Box>
            )}
            <Box
                sx={{
                    px: 2,
                    pt: 3,
                    pb: { xs: 10, sm: 12 },
                }}
            >
                <S.Title>Ranking</S.Title>

                {/* Podium Section */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        mb: 2,
                        mx: "auto",
                        maxWidth: { xs: "100%", sm: "90%", md: "80%" },
                    }}
                >
                    {[2, 1, 3].map((position) => {
                        const user = rankingData.find((u) => u.position === position);
                        // Create placeholder for missing positions
                        const placeholder = {
                            creator_id: `placeholder-${position}`,
                            avatar_url: '',
                            creator_name: `${position}º lugar`,
                            position: position,
                            points: '-'
                        };
                        const displayUser = user || placeholder;
                        return (
                            <Box
                                key={displayUser.creator_id}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    mx: { xs: 0.5, sm: 2 },
                                    width: displayUser.position === 1 ? 100 : 80,
                                    opacity: !user ? 0.5 : 1, // Make placeholder more transparent
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        mb: -3,
                                    }}
                                >
                                    <S.PodiumAvatar 
                                        src={displayUser.avatar_url || `https://ui-avatars.com/api/?name=${position}&background=random`} 
                                        isFirst={displayUser.position === 1} 
                                    />
                                    {displayUser.position === 1 && (
                                        <Box
                                            component="img"
                                            src={crownImage}
                                            sx={{
                                                position: "absolute",
                                                top: "-20px",
                                                zIndex: "2",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                width: { xs: 40, sm: 48 },
                                                height: "auto",
                                                filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.2))",
                                            }}
                                            alt="Crown"
                                        />
                                    )}
                                </Box>
                                <S.PodiumBox position={displayUser.position}>
                                    <Typography>{displayUser.position}º</Typography>
                                    <Typography>
                                        {displayUser.creator_name}
                                        {displayUser.current_user ? " (você)" : ""}
                                    </Typography>
                                    <Typography>{displayUser.points}{displayUser.points !== '-' ? ' pts' : ''}</Typography>
                                </S.PodiumBox>
                            </Box>
                        );
                    })}
                </Box>

                {/* Lista de usuários 4-10 */}
                {rankingData.length > 3 && (
                    <S.RankingListContainer>
                        {rankingData.slice(3, 10).map((user) => renderUserItem(user))}
                    </S.RankingListContainer>
                )}

                {/* Mostrar usuário atual apenas se estiver após a posição 10 */}
                {!isCurrentUserInTop3 && !isCurrentUserInTop10 && currentUser && currentUser.position > 10 && (
                    <Box sx={{ mb: 4 }}>{renderUserItem(currentUser)}</Box>
                )}

                {/* Scoring Period */}
                {currentPeriod && (
                    <S.ProgressSection>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            PERÍODO DE PONTUAÇÃO
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            {new Date(currentPeriod.data_inicio).toLocaleDateString()} até{" "}
                            {new Date(currentPeriod.data_fim).toLocaleDateString()}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={periodProgress}
                            sx={{
                                height: 8,
                                borderRadius: 4,
                                bgcolor: "#eee",
                                "& .MuiLinearProgress-bar": {
                                    bgcolor: "#FF4B55",
                                },
                            }}
                        />
                        <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
                            {Math.round(periodProgress)}% do período concluído
                        </Typography>
                    </S.ProgressSection>
                )}

                {/* Call to Action */}
                <S.CallToAction>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        CONTINUE ACUMULANDO PONTOS!
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        Participe ativamente na plataforma para subir no ranking e desbloquear recompensas exclusivas.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigator("/gamification")}
                        sx={{
                            bgcolor: "#00A3FF",
                            borderRadius: 2,
                            textTransform: "none",
                            py: 1.5,
                            px: 4,
                            "&:hover": {
                                bgcolor: "#0091EA",
                            },
                        }}
                    >
                        Começar a pontuar
                    </Button>
                </S.CallToAction>
            </Box>
        </S.Container>
    );
};
