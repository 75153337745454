import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Select, MenuItem, Box, useMediaQuery, useTheme } from "@mui/material";

export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const languageOptions = [
        { value: "pt", flag: "🇧🇷", title: "Português" },
        { value: "en", flag: "🇺🇸", title: "English" },
        { value: "es", flag: "🇪🇸", title: "Español" },
    ];

    const selectedLanguage = Cookies.get("language");

    const handleChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        Cookies.set("language", selectedLanguage, {
            sameSite: "Strict",
            secure: window.location.protocol === "https:",
            path: "/",
            expires: 365,
        });
    };

    return (
        <Box className="languageee">
            <Select
                id="languageSelect"
                value={selectedLanguage}
                onChange={handleChange}
                size="medium"
                sx={{
                    fontSize: isMobile ? 20 : 13,
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&:hover .MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                    color: 'inherit',
                }}
            >
                {languageOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value} selected={option.value === selectedLanguage}>
                        {option.flag}{!isMobile && ` ${option.title}`}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};
