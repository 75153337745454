import React, { useEffect, useState, useRef } from "react";
import { IconButton, Box } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const MUTE_PREFERENCE_KEY = "video-player-mute-preference";

export const VideoPlayer = ({ url, showControls = true, autoPlay = false, loop = false, defaultMuted = true, shouldCleanup = false }) => {
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(() => {
        const savedPreference = localStorage.getItem(MUTE_PREFERENCE_KEY);
        return savedPreference !== null ? savedPreference === "true" : defaultMuted;
    });

    const toggleMute = (e) => {
        e.stopPropagation();
        const newMuteState = !isMuted;
        setIsMuted(newMuteState);
        if (videoRef.current) {
            videoRef.current.muted = newMuteState;
        }
        localStorage.setItem(MUTE_PREFERENCE_KEY, newMuteState.toString());
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = isMuted;
        }
    }, [isMuted]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            if (autoPlay) {
                videoElement.play().catch((error) => {
                    console.log("AutoPlay failed:", error);
                });
            } else {
                videoElement.pause();
            }
        }

        return () => {
            if (shouldCleanup && videoElement) {
                videoElement.pause();
                videoElement.src = '';
                videoElement.load();
            }
        };
    }, [autoPlay, shouldCleanup]);

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                maxWidth: "480px",
                aspectRatio: "480/854",
                margin: "0 auto",
                backgroundColor: "#000",
                overflow: "hidden",
            }}
        >
            <video
                ref={videoRef}
                src={url}
                controls={showControls}
                autoPlay={autoPlay}
                loop={loop}
                playsInline
                preload="auto"
                width="480"
                height="854"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            />
            <IconButton
                onClick={toggleMute}
                size="small"
                sx={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                    padding: "4px",
                    opacity: 0.7,
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 1,
                    },
                }}
            >
                {isMuted ? <VolumeOffIcon sx={{ fontSize: 20 }} /> : <VolumeUpIcon sx={{ fontSize: 20 }} />}
            </IconButton>
        </div>
    );
};
