import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { AdminPanelSettings } from "@mui/icons-material";
import { Post } from "../../components/post";
import { RankingCard } from "./rankingCard";
import { Opportunities } from "./opportunities";
import { apiGetCommunity } from "../../services/apiGetCommunity";
import { useErrorStore } from "../../store/useErrorStore";
import { Loader } from "../../components/ui/Loader";
import { returnEnvironment } from "../../utils";
import { apiGetOportunidades } from "../../services/apiGetOportunidades";
import { creatorIsAdmin, isAdminOfClient } from "../../utils/adminManager";

export const Community = () => {
    const [isLoading, setIsLoading] = useState(null);
    const [community, setCommunity] = useState(null);
    const [opportunities, setOpportunities] = useState([]);
    const { id } = useParams();

    const showError = useErrorStore((state) => state.showError);
    const isWizard = returnEnvironment() === "wizard";

    const fetchData = async () => {
        try {
            setIsLoading(true);
            if (id) {
                const dataCommunity = await apiGetCommunity(id);
                setCommunity(dataCommunity);
            }
            const dataOpportunities = await apiGetOportunidades();
            setOpportunities(dataOpportunities);
        } catch (error) {
            console.log("error", error);
            showError(error);
            setCommunity(null);
            setOpportunities(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };
        effect();
    }, [id]);

    if (isLoading) return <Loader />;

    return (
        <Box sx={{ padding: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                {!isWizard && <Typography variant="h4">{community?.titulo}</Typography>}
                {creatorIsAdmin() && isAdminOfClient(community?.cliente) && (
                    <Box sx={{ marginLeft: "auto" }}>
                        <Tooltip title="Administrar Comunidade">
                            <IconButton color="primary" component={Link} to={`/comunidade/${id}/gerenciar`}>
                                <AdminPanelSettings />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>

            {opportunities?.length > 0 && <Opportunities opportunities={opportunities} />}
            <RankingCard community={community} />
            <Post community={community} />
        </Box>
    );
};
