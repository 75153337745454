const ADMIN_STATUS_KEY = "creator_admin_status";
const ADMIN_CLIENTS_LIST_KEY = "creator_admin_clients";

export const setAdminStatus = (creator) => {
    if (!creator) {
        clearAdminStatus();
        return;
    }

    const isAdmin = creator.admin_clientes && creator.admin_clientes.length > 0;
    localStorage.setItem(ADMIN_STATUS_KEY, JSON.stringify(isAdmin));

    if (isAdmin) {
        const adminClients = creator.admin_clientes.split(",").map((id) => id.trim());
        localStorage.setItem(ADMIN_CLIENTS_LIST_KEY, JSON.stringify(adminClients));
    }
};

export const clearAdminStatus = () => {
    localStorage.removeItem(ADMIN_STATUS_KEY);
    localStorage.removeItem(ADMIN_CLIENTS_LIST_KEY);
};

export const creatorIsAdmin = () => {
    const adminStatus = localStorage.getItem(ADMIN_STATUS_KEY);
    return adminStatus ? JSON.parse(adminStatus) : false;
};

export const getAdminClientsList = () => {
    const adminClients = localStorage.getItem(ADMIN_CLIENTS_LIST_KEY);
    return adminClients ? JSON.parse(adminClients) : [];
};

export const isAdminOfClient = (clientId) => {
    if (!clientId || !creatorIsAdmin()) {
        return false;
    }

    const adminClients = getAdminClientsList();
    return adminClients.includes(clientId.toString());
};
