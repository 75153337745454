import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apiGetCreators } from "../../../services/apiGetCreators";
import { apiGetPosts } from "../../../services/apiGetPosts";
import { Loader } from "../../../components/ui/Loader";
import { returnEnvironment } from "../../../utils";

export const MembersReport = () => {
    const { id: communityId } = useParams();
    const [creators, setCreators] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creatorStats, setCreatorStats] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [isUpdated, setIsUpdated] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            setIsUpdated(false);
            const [postsResponse, creatorsResponse] = await Promise.all([
                apiGetPosts(1, communityId),
                apiGetCreators(""),
            ]);

            const posts = Array.isArray(postsResponse) ? postsResponse : [];
            const creatorsData = Array.isArray(creatorsResponse) ? creatorsResponse : [];

            const filteredCreators =
                returnEnvironment() === "wizard"
                    ? creatorsData.filter((creator) => creator.cliente === 7)
                    : creatorsData;
            setCreators(filteredCreators);

            const stats = filteredCreators.map((creator) => {
                const creatorPosts = posts.filter((post) => post?.creator?.id === creator.id);
                const totalLikes = creatorPosts.reduce((sum, post) => {
                    const postLikes = post?.engajamento?.likes || [];
                    return sum + postLikes.length;
                }, 0);
                const totalComments = creatorPosts.reduce((sum, post) => {
                    const postComments = post?.engajamento?.comentarios || [];
                    return sum + postComments.length;
                }, 0);

                return {
                    id: creator.id,
                    nome: creator.nome,
                    email: creator.email,
                    totalPosts: creatorPosts.length,
                    totalLikes: totalLikes,
                    totalComments: totalComments,
                };
            });

            setCreatorStats(stats);
            setIsUpdated(true);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
            setCreatorStats([]);
            setIsUpdated(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [communityId]);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const sortedStats = React.useMemo(() => {
        if (!creatorStats || !Array.isArray(creatorStats)) return [];

        let sortedItems = [...creatorStats];
        if (sortConfig.key) {
            sortedItems.sort((a, b) => {
                if (!a || !b) return 0;

                let valA = a[sortConfig.key];
                let valB = b[sortConfig.key];

                if (valA < valB) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (valA > valB) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedItems;
    }, [creatorStats, sortConfig]);

    if (!isUpdated) {
        return <Loader />;
    }

    return (
        <Box>
            <Card sx={{ p: 3, mb: 3, mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Relatório de Membros
                </Typography>
                <TableContainer component={Paper} sx={{ boxShadow: "none", overflow: "auto" }}>
                    <Table sx={{ minWidth: 650 }}>
                        <colgroup>
                            <col style={{ width: { xs: "25%", sm: "20%" } }} />
                            <col style={{ width: { xs: "25%", sm: "30%" } }} />
                            <col style={{ width: { xs: "16.66%", sm: "16.66%" } }} />
                            <col style={{ width: { xs: "16.66%", sm: "16.66%" } }} />
                            <col style={{ width: { xs: "16.66%", sm: "16.66%" } }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort("nome")} style={{ cursor: "pointer" }}>
                                    Nome
                                </TableCell>
                                <TableCell onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                                    Email
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("totalPosts")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Total de Posts
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("totalLikes")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Curtidas Recebidas
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("totalComments")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Comentários Recebidos
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedStats.map((creator) => (
                                <TableRow key={creator.id}>
                                    <TableCell
                                        sx={{
                                            fontSize: { xs: "0.875rem", sm: "1rem" },
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            minWidth: "150px",
                                            maxWidth: "200px",
                                        }}
                                    >
                                        {creator.nome}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: { xs: "0.875rem", sm: "1rem" },
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            minWidth: "200px",
                                            maxWidth: "300px",
                                        }}
                                    >
                                        {creator.email}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {creator.totalPosts}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {creator.totalLikes}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {creator.totalComments}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {sortedStats.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        {loading ? "Carregando..." : "Nenhum membro encontrado"}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
};
