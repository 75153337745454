import React, { useState, useEffect } from "react";
import { Box, Drawer, Button, Typography } from "@mui/material";
import * as S from "./styles";
import { Opportunity } from "../../pages/opportunities/opportunity";
import { returnEnvironment } from "../../utils";
import { useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/useAuthStore";
import { apiGetMinhasOportunidades } from "../../services/apiGetMinhasOportunidades";

const OpportunityList = ({
    title,
    opportunities,
    isWizard,
    onSelect,
    isParticipatingSection = false,
    participatingOpportunities,
}) => (
    <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, color: "var(--text-primary-color)" }}>
            {title}
        </Typography>
        {opportunities?.length === 0 ? (
            <Typography variant="body2">Nenhuma oportunidade encontrada.</Typography>
        ) : (
            opportunities?.map((opportunity, index) => {
                const participation =
                    isParticipatingSection &&
                    participatingOpportunities?.find((item) => item.oportunidade === opportunity.id);

                const isPublished = participation?.post;

                return (
                    <S.OpportunityItem
                        key={index}
                        isWizard={isWizard}
                        onClick={() => onSelect(opportunity)}
                        className="opportunityCard"
                    >
                        <Box>
                            <S.BoldText isWizard={isWizard}>{truncateDescription(opportunity.titulo, true)}</S.BoldText>
                            <S.Subtitle isWizard={isWizard}>{`+${opportunity.recompensa_valor} pontos`}</S.Subtitle>
                        </Box>
                        {isParticipatingSection ? (
                            isPublished ? (
                                <Button variant="contained" className="opportunity-btn" disabled>
                                    Publicado
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className="opportunity-btn"
                                    sx={{
                                        backgroundColor: "var(--warning-color)",
                                        color: "var(--white-color)",
                                    }}
                                >
                                    Publicar Desafio
                                </Button>
                            )
                        ) : (
                            <Button
                                variant="contained"
                                className="opportunity-btn"
                                sx={{
                                    backgroundColor: "var(--primary-light-color)",
                                    color: "var(--white-color)",
                                    "&:hover": {
                                        backgroundColor: "var(--primary-light-color)",
                                    },
                                }}
                            >
                                Quero Participar
                            </Button>
                        )}
                    </S.OpportunityItem>
                );
            })
        )}
    </Box>
);

const truncateDescription = (text, isTitle) => {
    const maxLength = isTitle ? 22 : 50;
    if (text?.length <= maxLength) return text;
    return `${text.substring(0, maxLength - 3)}...`;
};

export const AllOpportunities = () => {
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [participatingOpportunities, setParticipatingOpportunities] = useState([]);
    const { state } = useLocation();
    const { opportunities } = state || [];
    const creator = useAuthStore((state) => state.creator);

    const isWizard = returnEnvironment() === "wizard";

    useEffect(() => {
        const fetchParticipatingOpportunities = async () => {
            try {
                const response = await apiGetMinhasOportunidades(creator.id);
                setParticipatingOpportunities(response);
                console.log("Minhas participações:", response);
            } catch (error) {
                console.error("Erro ao buscar participações:", error);
            }
        };

        if (creator?.id) {
            fetchParticipatingOpportunities();
        }
    }, [creator?.id]);

    console.log("Todas as oportunidades:", opportunities);

    const handleOpenOpportunity = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setDrawerOpen(true);
    };

    const handleCloseOpportunity = () => {
        setDrawerOpen(false);
        setSelectedOpportunity(null);
    };

    const validOpportunities = opportunities?.filter((opportunity) => {
        const currentDate = new Date();
        const inscricaoAte = new Date(opportunity.periodo_inscricao_ate);
        return opportunity.status !== "Encerrada" && inscricaoAte > currentDate;
    });

    const participatingIds = participatingOpportunities.map((item) => item.oportunidade);

    const availableOpportunities = validOpportunities?.filter(
        (opportunity) => !participatingIds.includes(opportunity.id),
    );

    const myOpportunities = validOpportunities?.filter((opportunity) => participatingIds.includes(opportunity.id));

    return (
        <>
            <S.Container>
                <S.Title variant="h5">Oportunidades</S.Title>
                <S.ScrollContainer>
                    <OpportunityList
                        title="Oportunidades para Participar"
                        opportunities={availableOpportunities}
                        isWizard={isWizard}
                        onSelect={handleOpenOpportunity}
                    />
                    <OpportunityList
                        title="Minhas Oportunidades"
                        opportunities={myOpportunities}
                        isWizard={isWizard}
                        onSelect={handleOpenOpportunity}
                        isParticipatingSection
                        participatingOpportunities={participatingOpportunities}
                    />
                </S.ScrollContainer>
            </S.Container>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseOpportunity}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: 2,
                        width: "100%",
                        maxWidth: "600px",
                        height: "100%",
                        backgroundColor: "var(--white-color)",
                        overflowY: "auto",
                    },
                }}
            >
                {selectedOpportunity && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Opportunity opportunity={selectedOpportunity} closeModal={handleCloseOpportunity} />
                    </Box>
                )}
            </Drawer>
        </>
    );
};
