import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { returnEnvironment } from "../../utils";

export const Learn = () => {
    const [LearnComponent, setLearnComponent] = useState(null);

    useEffect(() => {
        const loadLearnComponent = async () => {
            const environment = returnEnvironment();
            try {
                const module = await import(`./${environment}`);
                setLearnComponent(() => module.Learn);
            } catch (error) {
                console.error(`Erro ao carregar o módulo Learn para o ambiente ${environment}:`, error);
            }
        };

        loadLearnComponent();
    }, []);

    return LearnComponent ? <LearnComponent /> : null;
};
