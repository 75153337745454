import React, { useMemo, useState, useEffect, useRef } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Avatar,
    IconButton,
    Typography,
    Grid,
    Fab,
    Chip,
    Collapse,
    Box,
} from "@mui/material";
import {
    Favorite,
    ChatBubbleOutline,
    Add as AddIcon,
    FavoriteBorder,
    KeyboardArrowDown,
    KeyboardArrowUp,
    FilterList,
} from "@mui/icons-material";

import { PostCard } from "./PostCard";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePosts } from "../../hooks";
import { capitalizerFirstLetter, returnEnvironment } from "../../utils";
import { Loader } from "../../components/ui/Loader";
import { apiPostLike, apiDeleteLike } from "../../services";
import { useAuthStore } from "../../store/useAuthStore";
import { useErrorStore } from "../../store/useErrorStore";
import { LinkCard } from "./linkCard";
import { CATEGORY_DESAFIO } from "../../utils/constants";
import { TextEditor } from "../ui/TextEditor";
import { useFormattedText } from "../../hooks/useFormattedText";
import { VideoPlayer } from "../ui/VideoPlayer";

export const Post = ({ community }) => {
    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);
    const location = useLocation();
    const activeFilter = location.state?.activeFilter;

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showMyPosts, setShowMyPosts] = useState(false);
    const [showILikedPosts, setShowILikedPosts] = useState(false);
    const [mediaType, setMediaType] = useState("");
    const [postContent, setPostContent] = useState("");
    const { sanitizeContent, getTruncatedContent } = useFormattedText();

    const renderPostContent = (content, truncate = true, forceWhite = false) => {
        const finalContent = truncate ? getTruncatedContent(content) : content;
        let htmlContent = sanitizeContent(finalContent || "");
        if (forceWhite) {
            htmlContent = htmlContent.replace(/color\s*:\s*[^;"']+;?/gi, "");
        }
        return (
            <Box
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                sx={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    mt: 1,
                    "& p": { margin: 0, color: "inherit" },
                    "& *": {
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        color: "inherit",
                    },
                }}
            />
        );
    };

    const {
        data: postsData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        error,
    } = usePosts(community?.id, {
        author: showMyPosts ? creator.id : null,
        iLiked: showILikedPosts ? creator.id : null,
        categories: selectedCategories.length > 0 ? selectedCategories : null,
        type: mediaType || null,
        status: "APROVADO",
    });

    const posts = useMemo(() => postsData?.pages?.flatMap((page) => page) ?? [], [postsData]);
    const pinnedPosts = posts.filter((post) => post.pinado === true);
    const [likedPosts, setLikedPosts] = useState({});
    const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
    const videoObserver = useRef(null);

    useEffect(() => {
        const initialLikedPosts = {};
        posts.forEach((post) => {
            const isLikedByUser = post?.engajamento?.likes.some((like) => like?.creator === creator.id);
            initialLikedPosts[post.id] = {
                isLiked: isLikedByUser,
                likesCount: post.engajamento?.likes?.length,
            };
        });
        setLikedPosts(initialLikedPosts);
    }, [posts, creator.id]);

    const handlePostClick = (post) => {
        navigate(`/post/${post.id}`);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories((prev) =>
            prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category],
        );
    };

    const handleMediaTypeChange = (event, newType) => {
        setMediaType(mediaType === newType ? null : newType);
    };

    const handleToggleFilters = () => {
        setFiltersOpen(!filtersOpen);
    };

    const hasActiveFilters = useMemo(() => {
        return selectedCategories.length > 0 || showMyPosts || showILikedPosts || mediaType !== "";
    }, [selectedCategories, showMyPosts, showILikedPosts, mediaType]);

    if (error) return <p>Erro ao carregar posts.</p>;

    useEffect(() => {
        setShowMyPosts(activeFilter === "meus-posts");
        setShowILikedPosts(activeFilter === "curtidas");
    }, [activeFilter]);

    return (
        <div style={{ position: "relative", minHeight: "100vh" }}>
            {/* Filters Section */}
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        mb: 1,
                        pb: 0,
                        borderBottom: "1px solid var(--primary-color)",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            color: "var(--primary-color)",
                        }}
                    >
                        Posts da Comunidade
                    </Typography>

                    <IconButton
                        onClick={handleToggleFilters}
                        sx={{
                            color: hasActiveFilters ? "var(--primary-color)" : "inherit",
                            transform: filtersOpen ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "all 0.3s ease",
                            ...(hasActiveFilters && {
                                background: "rgba(255, 255, 255, 0.1)",
                                backdropFilter: "blur(10px)",
                                WebkitBackdropFilter: "blur(10px)",
                                border: "1px solid rgba(255, 255, 255, 0.18)",
                                boxShadow: "0 4px 12px 0 rgba(31, 38, 135, 0.15)",
                                "&:hover": {
                                    background: "rgba(255, 255, 255, 0.15)",
                                },
                            }),
                        }}
                    >
                        <FilterList />
                    </IconButton>
                </Box>

                <Collapse in={filtersOpen} sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            p: 2.5,
                            background: "rgba(255, 255, 255, 0.15)",
                            backdropFilter: "blur(10px)",
                            WebkitBackdropFilter: "blur(10px)",
                            borderRadius: 2,
                            border: "1px solid rgba(255, 255, 255, 0.18)",
                            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
                            position: "relative",
                            "&:before": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                    "linear-gradient(128deg, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0.06) 100%)",
                                borderRadius: 2,
                                zIndex: -1,
                            },
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 2,
                                fontWeight: 500,
                                color: "var(--primary-color)",
                            }}
                        >
                            Filtrar Posts
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Editoria:
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                            {[CATEGORY_DESAFIO, ...(community?.categorias || [])]?.map((category) => (
                                <Chip
                                    key={category}
                                    label={category}
                                    onClick={() => handleCategoryChange(category)}
                                    sx={{
                                        color: selectedCategories.includes(category)
                                            ? "var(--white-color)"
                                            : "var(--newpost-cattegorychiptext-color)",
                                        bgcolor: selectedCategories.includes(category)
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: selectedCategories.includes(category) ? 1 : 0.6,
                                        textTransform: "uppercase",
                                        "&:hover": {
                                            bgcolor: selectedCategories.includes(category)
                                                ? "var(--newpost-cattegorychiptextselected-color)"
                                                : "var(--newpost-cattegorychip-color)",
                                            opacity: selectedCategories.includes(category) ? 1 : 0.8,
                                        },
                                        "&:active": {
                                            bgcolor: selectedCategories.includes(category)
                                                ? "var(--newpost-cattegorychiptextselected-color)"
                                                : "var(--newpost-cattegorychip-color)",
                                            opacity: selectedCategories.includes(category) ? 1 : 0.8,
                                        },
                                    }}
                                />
                            ))}
                        </Box>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Autor:
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Chip
                                label="Meus Posts"
                                onClick={() => setShowMyPosts(!showMyPosts)}
                                sx={{
                                    color: showMyPosts
                                        ? "var(--white-color)"
                                        : "var(--newpost-cattegorychiptext-color)",
                                    bgcolor: showMyPosts
                                        ? "var(--newpost-cattegorychiptextselected-color)"
                                        : "var(--newpost-cattegorychip-color)",
                                    opacity: showMyPosts ? 1 : 0.6,
                                    "&:hover": {
                                        bgcolor: showMyPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showMyPosts ? 1 : 0.8,
                                    },
                                    "&:active": {
                                        bgcolor: showMyPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showMyPosts ? 1 : 0.8,
                                    },
                                }}
                            />
                        </Box>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Curtida:
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Chip
                                label="Posts que eu curti!"
                                onClick={() => setShowILikedPosts(!showILikedPosts)}
                                sx={{
                                    color: showILikedPosts
                                        ? "var(--white-color)"
                                        : "var(--newpost-cattegorychiptext-color)",
                                    bgcolor: showILikedPosts
                                        ? "var(--newpost-cattegorychiptextselected-color)"
                                        : "var(--newpost-cattegorychip-color)",
                                    opacity: showILikedPosts ? 1 : 0.6,
                                    "&:hover": {
                                        bgcolor: showILikedPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showILikedPosts ? 1 : 0.8,
                                    },
                                    "&:active": {
                                        bgcolor: showILikedPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showILikedPosts ? 1 : 0.8,
                                    },
                                }}
                            />
                        </Box>

                        {returnEnvironment() !== "wizard" && (
                            <>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        mb: 1,
                                        color: "var(--primary-dark-color)",
                                        fontWeight: 500,
                                    }}
                                >
                                    Exibir posts contendo:
                                </Typography>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                                    {["Vídeo", "Texto", "Foto", "Link"].map((type) => (
                                        <Chip
                                            key={type}
                                            label={type}
                                            onClick={() => handleMediaTypeChange(null, type.toLowerCase())}
                                            sx={{
                                                color:
                                                    mediaType === type.toLowerCase()
                                                        ? "var(--white-color)"
                                                        : "var(--newpost-cattegorychiptext-color)",
                                                bgcolor:
                                                    mediaType === type.toLowerCase()
                                                        ? "var(--newpost-cattegorychiptextselected-color)"
                                                        : "var(--newpost-cattegorychip-color)",
                                                opacity: mediaType === type.toLowerCase() ? 1 : 0.6,
                                                "&:hover": {
                                                    bgcolor:
                                                        mediaType === type.toLowerCase()
                                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                                            : "var(--newpost-cattegorychip-color)",
                                                    opacity: mediaType === type.toLowerCase() ? 1 : 0.8,
                                                },
                                                "&:active": {
                                                    bgcolor:
                                                        mediaType === type.toLowerCase()
                                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                                            : "var(--newpost-cattegorychip-color)",
                                                    opacity: mediaType === type.toLowerCase() ? 1 : 0.8,
                                                },
                                            }}
                                        />
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>
                </Collapse>
            </Box>

            {isLoading && <Loader />}
            <InfiniteScroll
                dataLength={posts.length - pinnedPosts.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<p>Carregando mais...</p>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Você chegou ao final!</b>
                    </p>
                }
            >
                <Grid container spacing={2} marginTop={0} marginBottom={10}>
                    {pinnedPosts.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "var(--primary-dark-color)",
                                        marginBottom: 2,
                                    }}
                                >
                                    Post Fixado
                                </Typography>
                            </Grid>

                            {pinnedPosts.map((post) => (
                                <Grid item xs={12} key={post.id}>
                                    <PostCard
                                        post={post}
                                        onClick={handlePostClick}
                                        isPinned={true}
                                        currentPlayingVideo={currentPlayingVideo}
                                        setCurrentPlayingVideo={setCurrentPlayingVideo}
                                        videoObserver={videoObserver}
                                        renderPostContent={renderPostContent}
                                        LinkCard={LinkCard}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}

                    {posts
                        .filter((post) => !post.pinado)
                        .map((post) => (
                            <Grid item xs={12} key={post.id}>
                                <PostCard
                                    post={post}
                                    onClick={handlePostClick}
                                    isPinned={false}
                                    currentPlayingVideo={currentPlayingVideo}
                                    setCurrentPlayingVideo={setCurrentPlayingVideo}
                                    videoObserver={videoObserver}
                                    renderPostContent={renderPostContent}
                                    LinkCard={LinkCard}
                                />
                            </Grid>
                        ))}
                </Grid>
            </InfiniteScroll>

            {community?.id > 0 && (
                <Fab
                    color="primary"
                    aria-label="postar"
                    style={{
                        position: "fixed",
                        bottom: 80,
                        right: 24,
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white-color)",
                        zIndex: 1000,
                    }}
                    onClick={() => navigate(`/novoPost/${community.id}`, { state: { community } })}
                >
                    <AddIcon />
                </Fab>
            )}
        </div>
    );
};
