import { Post } from "../http/httpVerbs";

export const apiPostStarRating = async (postId, stars, creator) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/posts/${postId}/rating`, {
            stars,
            creator,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
