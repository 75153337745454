import React from "react";
import { Card, CardContent, CardHeader, CardMedia, Avatar, Typography, Box } from "@mui/material";
import { VideoPlayer } from "../ui/VideoPlayer";

export const PostCard = ({
    post,
    onClick,
    isPinned,
    currentPlayingVideo,
    setCurrentPlayingVideo,
    videoObserver,
    renderPostContent,
    LinkCard,
}) => {
    return (
        <Card
            sx={{
                maxWidth: {
                    xs: 500,
                    md: 500,
                },
                margin: "auto",
                borderRadius: "16px",
                overflow: "hidden",
                backgroundColor: isPinned ? "var(--primary-dark-color)" : "#FFFFFF",
            }}
            onClick={() => onClick(post)}
        >
            <CardHeader
                avatar={<Avatar className="creatorAvatar">{post.creator?.nome?.charAt(0) ?? "A"}</Avatar>}
                title={
                    <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ color: isPinned ? "var(--white-color)" : "inherit" }}
                    >
                        {post.creator?.nome ?? "Anônimo"}
                    </Typography>
                }
                subheader={
                    <Typography sx={{ color: isPinned ? "var(--white-color)" : "inherit", opacity: 0.8 }}>
                        {post?.createdAt ? new Date(post?.createdAt).toLocaleString() : "Data indisponível"}
                    </Typography>
                }
            />

            {post?.media?.link && (
                <Box sx={{ width: "100%", boxSizing: "border-box", px: 2, mb: 2 }}>
                    <LinkCard
                        title={
                            <Typography sx={{ color: isPinned ? "var(--white-color)" : "inherit" }}>
                                Compartilhou uma publicação externa:
                            </Typography>
                        }
                        link={post?.media?.link}
                    />
                </Box>
            )}

            {post?.media?.videoUrl && (
                <Box
                    sx={{
                        px: 2,
                        width: "100%",
                        boxSizing: "border-box",
                    }}
                    ref={(el) => {
                        if (el) {
                            const observer = new IntersectionObserver(
                                (entries) => {
                                    entries.forEach((entry) => {
                                        // Check if at least 400px of the video is visible (about half)
                                        const visibleHeight = entry.intersectionRect.height;
                                        if (entry.isIntersecting && visibleHeight >= 400) {
                                            setCurrentPlayingVideo(post.id);
                                        } else {
                                            if (currentPlayingVideo === post.id) {
                                                setCurrentPlayingVideo(null);
                                            }
                                        }
                                    });
                                },
                                {
                                    threshold: [0, 0.25, 0.5, 0.75, 1],
                                    rootMargin: "-100px 0px",
                                },
                            );

                            observer.observe(el);
                            return () => observer.disconnect();
                        }
                    }}
                >
                    <VideoPlayer
                        url={post.media.videoUrl}
                        showControls={false}
                        autoPlay={currentPlayingVideo === post.id}
                        loop={true}
                        defaultMuted={true}
                    />
                </Box>
            )}

            {!post?.media?.videoUrl && post?.media?.imageUrl && (
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: { xs: "100vw", sm: "600px" },
                        margin: "0 auto",
                        aspectRatio: "1/1",
                        borderRadius: "16px",
                        overflow: "hidden",
                    }}
                >
                    <CardMedia
                        component="img"
                        src={post.media.imageUrl}
                        sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </Box>
            )}

            <CardContent sx={{ color: isPinned ? "var(--white-color)" : "inherit" }}>
                {renderPostContent(post.texto, true, isPinned)}
            </CardContent>
        </Card>
    );
};
